import Vue from 'vue'
import VueRouter from 'vue-router'

import { GetJumpApi} from '@/api/home.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/:uri*',
    name: 'home',
    component: () => import('@/views/home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next )=> { 
  if (!to.query.report_id) { 
    GetJumpApi({ uri: to.params.uri }).then(res => {
      location.href = location.href +'?'+res.query
      // location.href = res.query
     })
  }
  next()
})

export default router
